$(function() {

  //change order of hash tag menu from most to least

  //add post count to each tag
  function tagCount() {
    
    var tagList,
        tag,
        postCnt,
        listGroup = [];

    tagList = $(".tag_list li");    
    tag = $(".tag_list li a");

    //iterate over each
    for( i = 0; i < tag.length; i++ ) {

      listGroup.push(tag[i]);

      //get the number of posts from the title
      postCnt = parseInt(tag.eq(i).attr('title'));
      
      //append post count to list item
      tag.eq(i).append("<span class='post-count'>" + postCnt + "</span>");
    }

    //sort list items placing highest post count at top
    listGroup.sort( function(a,b) {
        return ((b.lastChild.innerHTML) - (a.lastChild.innerHTML));
    });

    //append sorted post items
    for( i = 0; i < listGroup.length; i++ ) {
      tagList.eq(i).append(listGroup[i]);
    }

    //initially show only first 7
    for( i = 0; i < 8; i++ ) {
      tagList.eq(i).addClass("is-shown");
    }

    //onclick show all button
    $(".show-all").on("click", function(){
        
        //add class to remaining 8+ that can be toggled
        for( i = 0; i < tagList.length; i++ ) {
          if(!tagList.eq(i).hasClass("is-shown")){
            tagList.eq(i).toggleClass("is-shown-all");
          }
        }
        //add class to show all button and change text
        if($(this).hasClass("less")){
          $(".cat-menu.on-right").animate({ scrollTop: -200 }, 600);
          $(this).toggleClass("less").text("Show more");
        } else {
          //change text to show less, add class
          $(this).toggleClass("less").text("Show less");
        }
      
    });

  };

  //wait for dom script that generates post count to finish
  setTimeout(tagCount, 4000);

});