$(function(){
	

	//change color of heart on click by adding class
	function likeHeart() {

		$(".like_button").off('click').on("click", function(e){
			e.preventDefault();
			e.stopPropagation();
			var $heartID = $(this).data("rel");
			$('.like_button[data-rel=' + $heartID +']').toggleClass("liked");
		});
	};
	//instantiate fn
	likeHeart();

	$("section.the-posts").bind('DOMNodeInserted', function(e) {
	    var element = e.target;
	    setTimeout(function() {
			likeHeart();
	    }, 2000);
	});

	//toggle left menu on click, shift content over
	$(".menu-toggle").click(function() {
		var menuTimeOut;

        $(".header-wrapper, section.the-posts, .banner-wrapper, .banner-bar").toggleClass("on-left");
    	$(".the-menu").addClass("on-left");
        
        if($(this).hasClass("on")){
        	menuTimeOut = setTimeout(function(){
        		$(".the-menu").removeClass("on-left");
        	},1000);
        } else {
        	clearTimeout(menuTimeOut);
        }
        $(this).toggleClass("on");
        if ($(".cat-toggle").hasClass("on")) {
            $(".cat-toggle").toggleClass("on")
            $(".cat-menu, .header-wrapper, section.the-posts, .banner-wrapper").toggleClass("on-right");
        }
    });

	//toggle right menu on click, shift content over
    $(".cat-toggle").click(function() {
        $(this).toggleClass("on");
        $(".cat-menu, .header-wrapper, section.the-posts,.banner-wrapper").toggleClass("on-right");

        if ($(".menu-toggle").hasClass("on")) {
            $(".menu-toggle").toggleClass("on");
        	var menuTimeOut = setTimeout(function(){
        		$(".the-menu").removeClass("on-left");
        	},1000);
            $(".header-wrapper, section.the-posts, .banner-wrapper, .banner-bar").toggleClass("on-left");
        }
    });

    $(".post-overlay").on("click", function(){
    	$(".header-wrapper, section.the-posts, .banner-wrapper, .banner-bar, .the-menu").removeClass("on-left");
    	$(".menu-toggle").removeClass("on");
    });
    $(".banner-wrapper").on("click", function(){
    	$(".header-wrapper, section.the-posts, .banner-wrapper, .banner-bar, .the-menu").removeClass("on-left");
    	$(".menu-toggle").removeClass("on");
    });
    //add class to hide img in lightbox overlay for video posts
    $(".lb-overlay").has("iframe").addClass("video-post");
    
    // //fix safari fuzzy image scaling issue
    // if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
    	
    // }


    //open links in new tab
    var links = $(".lb-overlay .caption p a");
	var len = links.length;

	for(var i=0; i<len; i++)
	{
	   links[i].target = "_blank";
	}

    //add text and play button on video posts
    // $(".trailer .post-overlay-tag").append("<div class='play'>View the trailer</div><div class='play-button'></div>");

    //re-direct from tumblr permalink page to home page
	if ( $("body").hasClass("permalink")) {
		var startUrl = window.location.origin + "#image-",
			imageID = window.location.pathname.split("/post/").pop().split("/")[0];
		window.location = startUrl + imageID;
	} 

	//onclick display modal overlay using .modal plugin method
	$('a[href="' + hashID +'"][rel="modal:open"]').click(function(e){
		event.preventDefault();
		$(this).modal();
	});

	//enable modal when user clicks back button
	window.onpopstate = function() {
			var hashID = window.location.hash;
		if (hashID && /^#image-\d+$/i.test(hashID)) {
			$('a[href="' + hashID +'"][rel="modal:open"]').modal();
		}
	};

	if (window.location.hash == "#trailer") {
		$('a[href="#trailer"][rel="modal:open"]').modal();
	}

	//if url has hash, check if it begins with '#image-' and display corresponding overlay
	var hashID = window.location.hash;
	if (hashID && /^#image-\d+$/i.test(hashID)) {

		if ($(hashID).length > 0) {
			$('a[href="' + hashID +'"][rel="modal:open"]').modal();
		} 
		else {
			//if post is not on 1st page make ajax call using tumblr api and load content that way
			var	imageID = hashID.split("#image-").pop();
			var apiURL = "http://api.tumblr.com/v2/blog/katygrannan.com/posts?&api_key=YBu4Pmaltk6fBgl8ckL9V9ZArrkgdmayKlYFMSQVYKqiX53YS0&111952415923&id=";
			var tumblrURL = apiURL + imageID;
			
			$.ajax({
			    url: tumblrURL,
			    dataType: 'jsonp',
			    success: function(data){
			    	var i,
			    		$link,
			    		$overlay,
						postType = data.response.posts[0].type,
						caption = data.response.posts[0].caption,
						tags = data.response.posts[0].tags,
						text = data.response.posts[0].text,
						source = data.response.posts[0].source,
						currentURL = window.location.origin + '/%23image-' + imageID;


						if(postType == "quote") {

							$overlay = $("<article class='type_quote'><div class='lb-overlay modal current'><div class='lb-inner-wrapper clearfix'><a class='lb-close' rel='modal:close'><div class='quote medium'><div class='quote-left'>“</div>"+ text +"<div class='quote-right'>”</div><div class='quote-author'>"+ source +"</div></div></a></div><div class='caption'><div class='post-overlay-date'><ul><li class='like'><div class='like_button' data-rel="+ imageID +"><svg width='100%' height='100%' viewBox='0 0 19 16' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='#ffffff'><path d='M14.0425097,0.000920262799 C16.1435097,-0.0400797372 18.8835097,1.28192026 18.9635097,5.36992026 C19.0525097,9.95492026 15.1985097,13.3079203 9.48350967,16.2089203 C3.76650967,13.3079203 -0.0874903349,9.95492026 0.00150966509,5.36992026 C0.0815096651,1.28192026 2.82150967,-0.0400797372 4.92250967,0.000920262799 C7.02450967,0.0419202628 8.87050967,2.26592026 9.46950967,2.92792026 C10.0945097,2.26592026 11.9405097,0.0419202628 14.0425097,0.000920262799 Z'></path></svg></div></li></ul></div><div class='tag main-tag'><a href='http://katygrannan.com/tagged/'" + tags[0] +"'>#" + tags[0] +"</a></div><div class='tag-wrapper'><div class='tag secondary-tag'></div><ul class='icons'><li class='sharer'><div class='sharer-wrap'><ul><li class='facebook'><a href='http://www.facebook.com/sharer.php?u="+ currentURL +"' title='Share on Facebook' class='social-facebook' target='_blank'></a></li><li class='twitter'><a href='https://twitter.com/intent/tweet?url="+ currentURL +"' title='Share on Twitter' class='social-tweets' target='_blank'></a></li><li class='pinterest'><a href='https://pinterest.com/pin/create/button/?url="+ currentURL +"' title='Share on Pinterest' class='social-pinterest' target='_blank'></a></li></ul></div></li></ul></div></div></div></article>");

						} else {

							var imgURL = data.response.posts[0].photos[0].alt_sizes[0].url;
							
							$overlay = $("<article class='type_photo'><div class='lb-overlay modal current'><div class='lb-inner-wrapper clearfix'><a class='lb-close' rel='modal:close'><img src='"+ imgURL +"'/></a></div><div class='caption'><div class='post-overlay-date'><ul><li class='like'><div class='like_button' data-rel="+ imageID +"><svg width='100%' height='100%' viewBox='0 0 19 16' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='#ffffff'><path d='M14.0425097,0.000920262799 C16.1435097,-0.0400797372 18.8835097,1.28192026 18.9635097,5.36992026 C19.0525097,9.95492026 15.1985097,13.3079203 9.48350967,16.2089203 C3.76650967,13.3079203 -0.0874903349,9.95492026 0.00150966509,5.36992026 C0.0815096651,1.28192026 2.82150967,-0.0400797372 4.92250967,0.000920262799 C7.02450967,0.0419202628 8.87050967,2.26592026 9.46950967,2.92792026 C10.0945097,2.26592026 11.9405097,0.0419202628 14.0425097,0.000920262799 Z'></path></svg></div></li></ul></div><div class='tag main-tag'><a href='http://katygrannan.com/tagged/'" + tags[0] +"'>#" + tags[0] +"</a>"+ caption +"</div><div class='tag-wrapper'><div class='tag secondary-tag'></div><ul class='icons'><li class='sharer'><div class='sharer-wrap'><ul><li class='facebook'><a href='http://www.facebook.com/sharer.php?u="+ currentURL +"' title='Share on Facebook' class='social-facebook' target='_blank'></a></li><li class='twitter'><a href='https://twitter.com/intent/tweet?url="+ currentURL +"' title='Share on Twitter' class='social-tweets' target='_blank'></a></li><li class='pinterest'><a href='https://pinterest.com/pin/create/button/?url="+ currentURL +"' title='Share on Pinterest' class='social-pinterest' target='_blank'></a></li></ul></div></li></ul></div></div></div></article> ");
							
						}

						for (i = 0; i < tags.length; i++) {
							$link = $('<a />', {
								href: "http://katygrannan.com/tagged/" + tags[i],
								text: '#' + tags[i],
								target: '_blank'
							});
							$(".tag.secondary-tag", $overlay).append($link);
						}

						$(".posts-holder").append($overlay);
						$overlay.modal();

						likeHeart();
			    }
			});
		}
	}

});