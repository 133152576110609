$(document).ready(function() {
    function t() {
        $(".photo--lightbox a").colorbox({
            photo: !0,
            scalePhotos: !0,
            maxHeight: "90%",
            maxWidth: "90%",
            transition: "none",
            fadeOut: 0
        });
        var t = $(".photoset-grid, this").attr("data-gutter");
        $(".photoset-grid, this").photosetGrid({
            highresLinks: !0,
            rel: "",
            gutter: t + "px",
            onComplete: function() {
                $(".photoset-grid.lightbox").attr("style", ""), $(".photoset-grid.lightbox a").colorbox({
                    photo: !0,
                    scalePhotos: !0,
                    maxHeight: "90%",
                    maxWidth: "90%",
                    transition: "none",
                    fadeOut: 0,
                    title: function() {
                        return $("img", this).attr("alt")
                    }
                }), $(".photoset-grid.lightbox").each(function() {
                    var t = $(this).attr("data-id");
                    $(this).find(".photoset-cell").attr("rel", t)
                }), $(".photoset-grid.none").each(function() {
                    $(this).find(".photoset-cell").attr("href", "")
                })
            }
        })
    }

    function i() {
        $(".video-container, this").each(function() {
            var t = $(this),
                i = t.children("iframe");
            if (i.length > 0) {
                var e = i.attr("width"),
                    o = i.attr("height"),
                    s = o / e * 100;
                t.css({
                    "padding-bottom": s + "%"
                });
                var a = i.attr("src").split("/"),
                    n = a[2];
                if ("instagram.com" === n && (t.css({
                        "padding-top": 40
                    }), t.parent().css({
                        "max-width": 620
                    })), t.hasClass("video-post-home") && 100 >= s) {
                    var r = 100 / s * 100,
                        d = r / 2;
                    t.css({
                        "padding-bottom": "100%",
                        top: 0,
                        width: r + "%",
                        left: "50%",
                        "margin-left": -d + "%"
                    })
                }
                if (t.hasClass("video-post-home") && s > 100) {
                    var h = (100 - s) / 2;
                    t.css({
                        top: h + "%"
                    })
                }
                t.hasClass("video-post-home") && -1 !== i.attr("src").indexOf("vimeo.com") && t.css({
                    left: 0,
                    "margin-left": 0
                }), t.addClass("ready")
            } else {
                var l = t.children("a"),
                    p = l.width(),
                    c = l.height(),
                    g = c / p * 100;
                t.css({
                    "padding-bottom": g + "%"
                }), l.css({
                    position: "absolute",
                    width: "100%",
                    height: "100%"
                }), l.children("div").css({
                    "background-size": 50
                }), t.addClass("ready")
            }
        })
    }

    function e() {
        $("body").hasClass("index") && ($(".post-overlay, this").hover(function() {
            $(this).siblings(".looper").looper({
                interval: 800,
                speed: 200
            }), $(this).siblings(".looper").hasClass("first-time") && ($(this).siblings(".looper").looper("next"), $(this).siblings(".looper").removeClass("first-time"))
        }, function() {
            $(this).siblings(".looper").looper("pause")
        }), $(".photoset-carousel").each(function() {
            $(this).imagesLoaded(function() {
                $(this).addClass("ready")
            })
        }))
    }

    // function o() {
    //     $(".js_ellipsis, this").dotdotdot({
    //         watch: "window"
    //     })
    // }

    function s() {
        $(".type_link").each(function() {
            var t = $(this).find(".the-link").attr("href");
            $(this).find(".post-overlay").attr("href", t)
        })
    }
    var a = navigator.userAgent.toLowerCase(),
        n = a.match(/(iphone|ipod|ipad)/);
    $("body").addClass(n ? "ios" : "no-ios"), $("body").hasClass("index") && ($(".the-posts article").each(function() {
        e(), i()
    }), s()), $("body").hasClass("index") || $(".the-posts article").each(function() {
        t(), i()
    })
    var r = $(".instagram-token").text(),
        d = parseInt(r.split(".")[0], 10),
        h = new Instafeed({
            get: "user",
            userId: d,
            accessToken: r,
            clientId: "11d9b23c34d547feb08648dce2ba0eb6",
            resolution: "standard_resolution",
            limit: 8,
            target: "instagram-feed",
            template: '<div class="photoset-carousel-item item zoom instagram-image" style="background-image: url({{image}})"><img src="{{image}}" /></div>',
            after: function() {
                $(".instagram-feed").addClass("on")
            }
        });
    if ($(".instagram-feed").length && (h.run(), $(".the-posts .posts-grid").prepend($(".instagram-feed"))), $(".the-posts .posts-grid").prepend($(".type_description")), $(".the-posts .posts-grid").append($(".type_pagination")), !$(".type_pagination").hasClass("pagination_standard")) {
        var l = $(".load-more-loading").text(),
            p = $(".load-more-end").text();
        $(".the-posts .posts-grid").infinitescroll({
            navSelector: ".pagination",
            nextSelector: ".pagination a.next",
            itemSelector: ".the-posts .posts-grid article",
            bufferPx: 100,
            loading: {
                selector: ".type_pagination .load-more",
                speed: 0,
                msgText: l,
                finishedMsg: p,
                finished: function() {
                    $("#infscr-loading").hide()
                }
            },
            errorCallback: function() {
                $(".type_pagination").addClass("off"), $("#infscr-loading").show(), setTimeout(function() {
                    $(".type_pagination").addClass("hidden")
                }, 2640)
            }
        }, function(t) {
            $(".the-posts .posts-grid").append($(".type_pagination"));
            var a = $(t).css({
                opacity: 0
            });
            a.animate({
                opacity: 1
            }), e(), s(), i()
        })
    }
    if ($(".type_pagination").hasClass("pagination_load-more") && ($(window).unbind(".infscr"), $(".pagination_load-more").click(function() {
            $(".the-posts .posts-grid").infinitescroll("retrieve")
        })), $(".scroll-top").click(function() {
            return $("body, html").animate({
                scrollTop: 0
            }, 800, "easeInOutQuint"), !1
        }), $(".the-posts").hasClass("single-post")) {
        var c = $(window).height(),
            g = $("header").height(),
            f = $("footer").outerHeight(),
            m = c - g - f;
        $(".the-posts").css({
            "min-height": m
        })
    }

});
